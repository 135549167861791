<template>
  <div
    class="d-flex justify-content-start align-content-center text-text-left flex-wrap gap-2"
  >
    <img
      v-if="isFirstName"
      alt="Visitor Profile Image"
      :src="profileImage"
      class="visitor-row-profile-image"
    />

    <!-- NAME -->
    <div class="my-auto visitor-row-name">
      {{ isFirstName ? visitor.profile.first_name : visitor.profile.last_name }}
    </div>
    <!-- NAME -->

    <!-- DATE OF BIRTH TOOLTIP -->
    <i
      v-if="isLastNameAndValidDob"
      :title="dateOfBirthTooltip"
      class="ri-information-line"
    />
    <!-- DATE OF BIRTH TOOLTIP -->
  </div>
</template>

<script>
import passHelpers from "@/helpers/index"
import { computed } from "vue"
import moment from "moment-timezone"

export default {
  name: "VisitorNameColumn",
  props: {
    visitor: {
      type: Object,
      default: () => ({})
    },
    isFirstName: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const profileImage = computed(() => {
      return (
        props?.visitor?.profile?.profile_image_uri ||
        props?.visitor?.profile?.profile_image_thumbnail_uri ||
        "/img/avatars/user-avatar.jpeg"
      )
    })

    const dateOfBirthTooltip = computed(() => {
      return `Date of Birth: ${sanitizeDate(
        props?.visitor?.profile?.date_of_birth
      )}`
    })

    const isLastNameAndValidDob = computed(() => {
      return (
        !props.isFirstName &&
        sanitizeDate(props?.visitor?.profile?.date_of_birth)
      )
    })

    const sanitizeDate = (date) => {
      const isValid = moment(date).isValid()
      const dateOfBirth = isValid
        ? passHelpers.convertToCurrentTimezone(date, false, "MM-DD-YYYY", true)
        : null
      return dateOfBirth
    }

    return {
      profileImage,
      isLastNameAndValidDob,
      dateOfBirthTooltip
    }
  }
}
</script>

<style scoped></style>
